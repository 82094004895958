<template>
    <div class="answer-container">
        <CommonBlock class="common-block" title="Голосування" :breadcrumbs="[{id: 1, name: 'Позиція партії', link: '/position' }, {id: 2, name: 'Голосування'}]">
            <div class="img">
                 <img v-if="isDeputy" src="@/assets/images/voting_deputy.png" alt="image">
                <img v-else src="@/assets/images/voting_partiec.png" alt="image">
            </div>
        </CommonBlock>
        <div class="content">
            <div class="answer">
                <div class="title">{{voting.name}}</div>
                <div class="description" v-html="voting.description"></div>
                <div class="count">Голосів {{voting.votingUserAnswersCount}}</div>
                <div class="statistics">
                    <div class="chart">
                        <Doughnut class="chart-container" :options="chartOptions" :chart-data="chartData"/>
                    </div>
                    <div class="list-statistics">
                        <div class="head">
                            <div class="options">Варіанти відповіді</div>
                            <div class="votes">Кількість голосів</div>
                        </div>
                        <div class="body">
                            <div class="item" v-for="item in voting.votingResultCount || []">
                                <div class="title">
                                    <div class="color" :style="{backgroundColor: item.color}"></div>
                                    <div class="description">{{item.votingAnswer.name}}</div>
                                </div>
                                <div class="stat">
                                    <div class="count-number">{{item.count}}</div>
                                    <div class="interest">{{item.interest}} %</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="active-result mobile">
                        Моя відповідь: {{voting.userAnswer ? voting.userAnswer.name : ''}}
                    </div>
                </div>
                <div class="active-result desktop">
                    Моя відповідь: {{voting.userAnswer ? voting.userAnswer.name : ''}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonBlock from "../../components/CommonBlock";
import Doughnut from "@/components/Doughnut";
import VotingService from "../../services/VotingService";


export default {
    name: "Voting-answer",
    components:{
        CommonBlock,
        Doughnut
    },
    data(){
        return {
            voting: {},
            isDeputy: this.$store.getters.identity.is_deputy,
            view: {
                id: 1,
                short_description: 'Вакцинація не може розпочатися – через фізичну відсутність вакцини в Україні. Чи підтримуєте Ви дії влади?',
                result_count: 43,
                status: 1,
                entity: [
                    {
                        id: 1,
                        name: 'Повністю підтримую',
                        count: 23
                    },
                    {
                        id: 2,
                        name: 'Підтримую',
                        count: 12
                    },
                    {
                        id: 3,
                        name: 'Частково підтримую',
                        count: 2
                    },
                    {
                        id: 4,
                        name: 'Не маю чіткої позиції',
                        count: 7
                    },
                    {
                        id: 5,
                        name: 'Не підтримую',
                        count: 21
                    },
                    {
                        id: 6,
                        name: 'Вважаю дії влади ганебними',
                        count: 5
                    },
                    {
                        id: 7,
                        name: 'Вважаю дії влади ганебними',
                        count: 8
                    },
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                showLines: false,
                animation: {
                    animateRotate: false
                },
                tooltips: {
                    enabled: false
                },
                hover: {mode: null},
                legend: {
                    display: false
                },
            },
            id: null,
            dataAnswer: [],
            answerId: null,
            channelsStats: [2, 23, 43, 43,1],
            channelsColor: [
                "#18b4f8",
                "#ebf818",
                "#ff8a18",
                "#DFC365",
                "#3dea99",
                "#5B8E57",
                "#b601d9",
                "#FCABE5",
                "#f40035",
                "#A25000",
                "#6164CA",
                "#1E18F8",
                "#555165",


            ],
        }
    },
    methods:{
        sortInterest(){
            let sum;
            (this.voting.votingResultCount || []).forEach(item => {
                sum = sum ? sum + +item.count : +item.count
            });

            let countColor = 0;
            if (sum) {
                (this.voting.votingResultCount || []).forEach(item => {
                    item.interest = Math.round((+item.count * 100)/sum)
                    item.color = this.channelsColor[countColor]
                    ++countColor
                })
            }
        }
    },
    computed: {
        chartData() {
            let dataset = {
                borderWidth: 2,
                backgroundColor: [],
                data: []
            };
            let countColor = 0;
            (this.voting.votingResultCount || []).forEach(item => {
                dataset.backgroundColor.push(this.channelsColor[countColor]);
                ++countColor
                dataset.data.push(item.count);
            });

            return {datasets: [dataset]};
        }
    },
    created() {
        VotingService.getVotingResult(this.$route.params.id).then(resp => {
            this.voting = resp.data;
            this.sortInterest()
        });
    }
}
</script>

<style scoped lang="scss">
.answer-container{
    display: flex;
    min-height: 100vh;
    @media (max-width: 992px) {
        flex-direction: column;
    }
    .content{
        width: 100%;
        margin-top: 40px;
        background-color: #f5f5f5;
        margin-left: 26.7%;
        @media (max-width: 992px) {
            margin-left: 0;
        }
        .answer{
            margin: 40px;
            background-color: white;
            padding: 40px;
            border-radius: 10px;
            @media (max-width: 992px) {
                margin: 0;
                border-radius: 0;
            }
            .title{
                font-size: 30px;
                font-weight: 600;
                color: #1a2744;
                margin-bottom: 20px;
                margin-right: 5px;
                display: flex;
                align-items: center;
                @media (max-width: 778px) {
                    width: 92%;
                }

            }
            .color{
                width: 20px;
                min-width: 20px;
                height: 12px;
                border-radius: 12px;
                margin-right: 10px;
            }
            .count{
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
                color: #1a2744;
                padding: 4px 15px;
                border-radius: 8px;
                background-color: #f5f5f5;
                display: inline-block;
            }


        }
    }


    .list-statistics{
        width: 100%;
        margin-left: 40px;
        @media (max-width: 992px) {
            margin-top: 50px;
            margin-left: 0;
        }
        @media (max-width: 330px) {
            width: 110%;
        }
        .head{
            display: flex;
            justify-content: space-between;
            .options, .votes{
                font-size: 12px;
                color: #1a2744;
            }
            .votes{
                @media (max-width: 330px) {
                    min-width: 110px;
                }
            }
        }
    }
    .statistics{
        display: flex;
        width: 100%;
        //height: 204px;
        border-top: 1px solid #f5f5f5;
        padding-top: 46px;
        margin-top: 20px;
        //padding-bottom: 240px;
        border-bottom: 1px solid #f5f5f5;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        .chart{
            width: 204px;
            height: 204px;
            .chart-container{
                position: relative;
                bottom: 100px;
            }
        }
        .body{
            margin-top: 12px;
            .item{
                display: flex;
                justify-content: space-between;
                line-height: 12px;
                align-items: center;
                background-color: white;
                @media (max-width: 778px) {
                    width: 100%;
                }
                .stat{
                    width: 102px;
                    display: flex;
                    justify-content: space-between;
                    min-width: 110px;
                    @media (max-width: 778px) {
                        width: 40%;
                        min-width: auto;
                    }
                }
                .description{
                    font-size: 16px;
                    color: #1a2744;
                    font-weight: normal;
                    margin-bottom: 0;
                    line-height: 20px;
                    @media (max-width: 768px) {
                        font-size: 13px;
                    }
                }
                .count-number, .interest{
                    font-size: 18px;
                    font-weight: bold;
                    color: #1a2744;
                }
            }
        }

    }

    .active-result{
        width: 100%;
        min-height: 80px;
        border-radius: 10px;
        background-color: #cdd4e0;
        margin-top: 25px;
        font-size: 24px;
        font-weight: 500;
        color: #1a2744;
        display: flex;
        align-items: center;
        //padding-left: 40px;
        padding: 0 20px;
        border-top: 1px solid #f5f5f5;
        @media (max-width: 992px) {
            //margin-top: 285px;
            //position: relative;
            //top: 160px;
        }
        @media (max-width: 540px) {
            font-size: 14px;
        }
    }
    .mobile{
        display: none;
    }
    @media (max-width: 992px) {
        .mobile{
            display: flex;
        }
        .desktop{
            display: none;
        }
    }
}
::v-deep{
    .common-block{
        position: fixed;
        width: 26.7%;
        @media (max-width: 992px) {
            min-height: 25vh;
            position: static;
            width: auto;
        }
        .body{
            .title{
                //border-bottom: 1px solid blue;
            }
        }
        .slot{
            @media (max-width: 992px) {
                display: none;
            }
            .img{
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }

        footer{
            .btn-back_icon{
                background-color: #ffc900;
            }
        }

    }
}
</style>
<style lang="scss">
.description{
    font-size: 24px;
    font-weight: 600;
    color: #1a2744;
    margin-bottom: 20px;
}
</style>
